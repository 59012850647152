*,
::after,
::before {
  box-sizing: border-box;
}

:root {
  --highlighted-color: #ff262a;
  --primary-text-color: #ffffff;
  --secondary-text-color: #ffffff;
}
@font-face {
  font-family: light-font;
  src: url(./fonts/pepul-font-light.woff);
}
@font-face {
  font-family: regular-font;
  src: url(./fonts/pepul-font-regular.woff);
}
@font-face {
  font-family: medium-font;
  src: url(./fonts/pepul-font-medium.woff);
}
@font-face {
  font-family: bold-font;
  src: url(./fonts/pepul-font-bold.woff);
}

body {
  font-family: regular-font;
  font-size: 14px;
  line-height: 1.8;
  padding: 0;
  margin: 0;
  color: var(--primary-text-color);
  background: #100f42;
}
.pepul-quiz {
  background: url(img/bg.png) no-repeat;
  background-size: cover;
  min-height: 900px;
  padding: 0px 0px 20px;
  position: relative;
}
h1,
h2,
p,
span,
div {
  padding: 0;
  margin: 0;
}
.quiz-gif {
  text-align: center;
  height: 140px;
  overflow: hidden;
}
.quiz-gif img {
  width: 300px;
  transform: translate(0px, -75px);
}
.quiz-text {
  font-family: bold-font;
  text-transform: uppercase;
  text-align: center;
  position: relative;
}
.quiz-text p span {
  font-size: 20px;
  transform: translate(0px, -8px);
  color: #ffb70e;
  display: inline-block;
}
.quiz-text h1 {
  margin-bottom: 15px;
  font-size: 25px;
}
.quiz-text h1 span {
  color: #ffb70e;
}
.quiz-text img {
  width: 250px !important;
  position: relative;
  z-index: 2;
}
.video-quiz {
  text-align: center;
  margin-top: 5px;
  padding: 0px 10px;
}
.video-quiz p {
  font-size: 18px;
  font-family: medium-font;
}
.video-quiz img {
  max-width: 100%;
  margin-top: 15px;
}
.video-quiz button {
  font-size: 16px;
  background: #ffffff;
  border-radius: 12px;
  border: 0;
  min-width: 350px;
  padding: 15px 10px;
  margin-top: 10px;
  font-family: medium-font;
  margin-bottom: 15px;
}
.time-quiz {
  text-align: center;
  margin-top: 10px;
}
.time-quiz p {
  font-size: 15px;
  margin-top: 10px;
}
#countdown ul,
#countdown1 ul {
  padding-left: 30px;
  position: relative;
  margin: 0;
}
#countdown ul li,
#countdown1 ul li {
  display: inline-block;
  list-style-type: none;
  padding: 1em;
  font-size: 12px !important;
  text-transform: capitalize;
  color: #b0b0b0;
  position: relative;
}

#countdown ul li p,
#countdown1 ul li p {
  color: #b0b0b0;
  font-size: 12px !important;
  margin-top: -12px;
}

#countdown ul li h3,
#countdown1 ul li h3 {
  font-size: 35px !important;
  font-family: bold-font;
  color: #ffcb00;
  margin: 0;
}
#countdown ul li p::after,
#countdown1 ul li p::after {
  content: ":";
  color: #fff;
  position: absolute;
  right: -6px;
  top: 13px;
  font-size: 30px;
  font-family: bold-font;
}
#countdown ul li:last-child p::after,
#countdown1 ul li:last-child p::after {
  content: "";
}
.timer-li {
  position: absolute !important;
  left: 9%;
  top: 2px;
}
.timer-li img {
  width: 75px;
}
.today-quiz {
  background: #d12525;
  min-height: 100px;
  padding: 20px 15px;
  margin-top: 15px;
}
.today-quiz .text-p {
  width: 60%;
  float: left;
  font-size: 22px;
  font-family: bold-font;
  line-height: 1.4;
}
.today-quiz .button-p {
  width: 40%;
  float: left;
}
.today-quiz .button-p button {
  background: #f6ad00;
  border: 0;
  padding: 15px 22px;
  font-size: 18px;
  border-radius: 10px;
  width: 100%;
  font-family: medium-font;
  position: relative;
  overflow: hidden;
}
.winner-text {
  margin-top: 15px;
  text-align: center;
}
.winner-text p {
  color: #ffbf00;
  font-size: 16px;
  text-transform: uppercase;
  font-family: medium-font;
}
.winner-bg {
  background: url(img/winner-bg.png);
  min-height: 100px;
  background-size: cover;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 0px;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
}
.user-profile {
  align-items: center;
  display: flex;
}
.user-profile img {
  width: 78px;
}
.user-name {
  align-items: center;
  display: flex;
}
.user-name p {
  text-transform: capitalize !important;
  color: #fff;
  font-size: 16px;
  text-align: left;
  line-height: 1.4;
}
.user-name p span {
  font-family: regular-font;
  font-size: 14px;
  text-transform: lowercase;
}
.user-amount {
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}
.user-amount p {
  color: #ffc804;
  font-size: 30px;
  font-family: bold-font;
  position: relative;
  z-index: 2;
}
.coin-animation {
  position: absolute;
  top: -20px;
  left: -30px;
  z-index: 1;
}
.coin-animation img {
  width: 200px;
}
.hide-li {
  display: none !important;
}

/* Quiz */

.quiz-question {
  position: relative;
}
.seconds-gif {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
}
.seconds-gif img {
  width: 80px;
}
.quiz-board {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #000;
  padding: 20px;
  border-radius: 15px;
  margin: 5px 20px;
  font-size: 20px;
  font-family: "bold-font";
  word-spacing: 4px;
}
.quiz-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 20px 15px;
}
.quiz-banner img {
  max-width: 100%;
}
.quiz-answer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 20px 15px;
}
.quiz-answer ul {
  list-style: none;
  width: 100%;
  padding-left: 0px;
}
.quiz-answer ul li {
  text-align: left;
  width: 100%;
  border: 1px solid rgb(255 255 255 / 28%);
  margin-bottom: 20px;
  padding: 12px 20px;
  border-radius: 10px;
  font-size: 20px;
  font-family: "bold-font";
  background-image: linear-gradient(
    to right,
    rgb(255 255 255 / 23%) 0%,
    #100f42 100%
  );
  letter-spacing: 0.5px;
}
.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
}
.wrong-answer {
  background: #c60000;
  background-image: unset !important;
}
.correct-answer {
  background: #11ae00;
  background-image: unset !important;
  animation: shake1 0ms 2 linear !important;
}

/* Quiz Reward */

.quiz-reward {
  position: relative;
  /* display: none; */
}
.reward-text {
  text-align: center;
  margin-bottom: 15px;
  position: relative;
  z-index: 2;
}
.rupees {
  text-align: center;
  margin-top: 10px;
}
.reward-text p {
  font-size: 24px;
  font-family: "medium-font";
}
.reward-text img {
  width: 100px;
  margin-top: 10px;
}
.reward-text p span {
  color: #febe04;
}
.reward-text .quiz-text img {
  width: 250px !important;
  margin-top: 15px;
}
.rupees img {
  width: 200px !important;
}
.reward-button {
  padding: 10px 20px;
}
.reward-button p {
  font-size: 18px;
  font-family: "regular-font";
  margin-bottom: 10px;
}
.reward-winners {
  text-align: center;
  width: 100%;
  border: 1px solid #a7a7a7;
  margin-bottom: 20px;
  padding: 15px 20px;
  border-radius: 10px;
  font-size: 20px;
  background: transparent;
  background-image: linear-gradient(
    to right,
    rgb(149 149 149 / 56%) 0%,
    #100f42 100%
  );
  color: #fff;
  font-family: "medium-font";
  display: none;
}
.claim-reward {
  background: #f6ad00;
  border: 0;
  padding: 15px 22px;
  font-size: 20px;
  border-radius: 10px;
  width: 100%;
  font-family: medium-font;
  position: relative;
  overflow: hidden;
}
.m_scroll_arrows {
  display: inline-block;
  width: 5px;
  height: 5px;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(312deg);
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  margin: 0 0 0px -6px;
  width: 12px;
  height: 12px;
}

.unu,
.doi,
.trei {
  -webkit-animation: mouse-scroll 1s infinite;
  -moz-animation: mouse-scroll 1s infinite;
  animation: mouse-scroll 1s infinite;
}

.unu {
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  animation-delay: alternate;
  margin-left: 10px;
}

.doi {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -webkit-animation-direction: alternate;
  animation-delay: 0.2s;
  animation-direction: alternate;
  margin-top: -6px;
}

.trei {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -webkit-animation-direction: alternate;
  animation-delay: 0.3s;
  animation-direction: alternate;
  margin-top: -6px;
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.share {
  margin-top: 10px;
}
.share p {
  font-size: 16px;
  text-align: center;
}
.social-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.social-icons img {
  width: 45px;
  margin-right: 15px;
}

/* Better luck */
.loser-popup {
  background: url(img/quiz-bg.png);
  min-height: 900px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0px;
  background-position: center;
  display: flex;
  justify-content: center;
  text-align: center;
  filter: saturate(1.3);
  padding: 20px;
}
.better-luck p {
  font-size: 20px;
}
.better-luck h3 {
  font-family: "bold-font";
  font-size: 26px;
  margin: 10px 0px;
  line-height: 1.5;
}
.better-luck p span {
  font-size: 18px;
}
.one-rupee {
  width: 120px;
  margin-top: 30px;
}
.clock h1 {
  font-size: 40px;
  font-family: "bold-font";
}
.better-luck .time-quiz p {
  font-size: 16px;
}
.better-luck .claim-reward {
  margin-top: 15px;
}
.confetti {
  position: absolute;
  text-align: center;
  top: -180px;
  z-index: 1;
}
.confetti img {
  width: 100% !important;
}
.language {
  position: absolute;
  right: 15px;
  top: 20px;
}
.video-quiz video {
  width: 100%;
  margin-top: 10px;
}
.button-p button::after,
.claim-reward::after {
  content: "";
  width: 20px;
  height: 200px;
  position: absolute;
  left: -20px;
  top: 0;
  margin-top: -20px;
  background: #ffdf94;
  opacity: 0.6;
  animation: login-blur 2s infinite;
  animation-delay: 0s;
  transform-origin: top;
}

.button-p button::before,
.claim-reward::before {
  content: "";
  width: 10px;
  height: 200px;
  position: absolute;
  left: -50px;
  top: 0;
  margin-top: -20px;
  background: #ffdf94;
  opacity: 0.6;
  animation: login-blur 2s infinite;
  animation-delay: 0s;
  transform-origin: top;
}

@keyframes login-blur {
  from {
    transform: translate(0) rotate(45deg);
  }
  to {
    transform: translateX(470px) rotate(45deg);
  }
}

.main-video {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 90%);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-video video {
  width: 100%;
}
.tamil .today-quiz .text-p {
  width: 55%;
}
.tamil .today-quiz .button-p {
  width: 45%;
  float: left;
}
.wrong-answer {
  animation: shake 150ms 2 linear;
  -moz-animation: shake 150ms 2 linear;
  -webkit-animation: shake 150ms 2 linear;
  -o-animation: shake 150ms 2 linear;
}
@-webkit-keyframes shake {
  0% {
    -webkit-transform: translate(3px, 0);
  }
  50% {
    -webkit-transform: translate(-6px, 0);
  }
  100% {
    -webkit-transform: translate(0, 0);
  }
}
.payment-info {
  padding: 20px;
}
.payment-info h3 {
  font-size: 20px;
}
.payment-info p {
  color: #aeaeae;
}
.payment-info form label {
  display: block;
  margin-top: 15px;
}
.payment-info form input {
  width: 100%;
  background: rgb(255 255 255 / 10%);
  border: 1px solid rgb(255 255 255 / 50%);
  border-radius: 10px;
  min-height: 60px;
  font-size: 17px;
  padding-left: 20px;
  margin: 10px 0px 20px;
  color: #fff;
  outline: transparent !important;
}

.final-quiz {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 20%;
}
.final-quiz img {
  width: 200px;
}
.final-quiz h3 {
  font-family: "medium-font";
  font-size: 25px;
  color: #febe04;
  margin-bottom: 10px;
}
.final-quiz p {
  font-size: 18px;
}
.today-quiz {
  background: #d12525;
  min-height: 100px;
  padding: 0px 15px;
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.tamil .video-quiz p {
  font-size: 22px;
}
.tamil .video-quiz button {
  font-size: 18px;
}
